.stack {
  display: flex;
  flex-direction: column;
}

.narrow-container {
  width: 1000px;
  max-width: 100%;

  @media screen and (max-width: 1441px) {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}

.container {
  width: var(--container-width);
  max-width: 100%;

  @media screen and (max-width: 1441px) {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}

.px-gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.pl-gutter {
  padding-left: var(--gutter);
}

.pr-gutter {
  padding-right: var(--gutter);
}

.pt-gutter {
  padding-top: var(--gutter);
}

.pb-gutter {
  padding-bottom: var(--gutter);
}

.gap-gutter {
  gap: var(--gutter);
}

.gap-page {
  gap: var(--gutter);
}

.tab {
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: var(--gold-color);
  }
}

.tab[data-state="active"] {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: var(--gold-color);
  }
}
/* .tab-active {
  color: var(--gold-color);
} */

.top-gutter {
  top: var(--gutter);
}

.left-gutter {
  left: var(--gutter);
}

.right-gutter {
  right: var(--gutter);
}

.light-placeholder {
  &::placeholder {
    color: var(--white-color);
  }
}

.flex-card {
  flex: 0 0 480px;
  max-width: 100%;

  @media screen and (max-width: 600px) {
    flex: 0 0 100%;
  }
}

.flex-swatch {
  flex: 0 0 240px;
}
