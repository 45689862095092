h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--hero-font-family);
}

h1 {
  line-height: var(--line-height-heading);
}

h2 {
  line-height: var(--line-height-heading);
}

h3 {
  line-height: var(--line-height-heading);
}

h4 {
  line-height: var(--line-height-heading);
}

h5 {
  line-height: var(--line-height-heading);
}

h6 {
  line-height: var(--line-height-heading);
}

body {
  font-size: var(--body-font-size);
  line-height: var(--line-height-body);
}

small {
  font-size: var(--small-font-size);
}

.text {
  font-size: var(--body-font-size);
  line-height: var(--line-height-body);
}

p {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  p {
    font-size: 1.5rem;
    line-height: 2.25rem;

    @media screen and (max-width: 768px) {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
}
