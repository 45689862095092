:root {
  // COLOR

  // Brand colors
  --yellow-color: #f9d71c;
  --black-color: #000000;
  --white-color: #ffffff;
  --blue-color: #0a84ff;
  --red-color: #ff0000;
  --green-color: #00ff00;
  --gray-color: #808080;
  --light-gray-color: #ededed;
  --dark-gray-color: #2b2b2b;
  --teal-color: #154c47;
  --gold-color: #b7974a;
  --maroon-color: #632331;

  --primary-color: var(--black-color);
  --primary-text-color: var(--white-color);
  --primary-hover-color: #f9d71c;

  --secondary-color: var(--yellow-color);
  --secondary-text-color: var(--black-color);
  --secondary-hover-color: #f9d71c;

  --tertiary-color: var(--red-color);
  --tertiary-text-color: var(--white-color);
  --tertiary-hover-color: #ff0000;

  --error-color: var(--red-color);
  --error-text-color: var(--white-color);

  --success-color: var(--green-color);
  --success-text-color: var(--white-color);

  --background-color: #f5f5f5;
  --text-color: #2b2b2b;
  --nav-background: #000;
  --nav-color: #fff;

  // TYPOGRAPHY

  //h1 = 96px
  //h2 = 64px
  //h3 = 40px
  //h5 = 24px
  //h6 = 18px
  //body = 18px

  --step--2: clamp(0.4444rem, 0.4251rem + 0.0966vi, 0.5rem);
  --step--1: clamp(0.6667rem, 0.6377rem + 0.1449vi, 0.75rem);
  --step-0: clamp(1rem, 0.9565rem + 0.2174vi, 1.125rem);
  --step-1: clamp(1.5rem, 1.4348rem + 0.3261vi, 1.6875rem);
  --step-2: clamp(2.25rem, 2.1522rem + 0.4891vi, 2.5313rem);
  --step-3: clamp(3.375rem, 3.2283rem + 0.7337vi, 3.7969rem);
  --step-4: clamp(5.0625rem, 4.8424rem + 1.1005vi, 5.6953rem);
  --step-5: clamp(7.5938rem, 7.2636rem + 1.6508vi, 8.543rem);

  --hero-font-size: clamp(5.625rem, 4.1033rem + 7.6087vi, 10rem);
  --h1-font-size: clamp(3.75rem, 2.9674rem + 3.913vw, 6rem);
  --h2-font-size: clamp(3.25rem, 2.9891rem + 1.3043vw, 4rem);
  --h3-font-size: clamp(2rem, 1.8571rem + 0.7143vw, 2.5rem);
  --h4-font-size: var(--step-3);
  --h5-font-size: var(--step-2);
  --h6-font-size: var(--step-1);
  --body-font-size: 1.25rem;
  --small-font-size: var(--step--1);
  --large-body-font-size: var(--step-1);

  --button-font-size: var(--step-1);
  --button-font-weight: 500;

  --hero-font-family: "neue-haas-unica", Helvetica, sans-serif;
  --heading-font-family: "neue-haas-unica", Helvetica, sans-serif;
  --body-font-family: "neue-haas-unica", Helvetica, sans-serif;
  --quote-font-family: "neue-haas-unica", Helvetica, sans-serif;

  --line-height-body: 1.5;
  --line-height-heading: 1.1;
  --line-height-huge: 0.9;

  --border-radius: 4px;
  --border-width: 1px;
  --border-color: #e0e0e0;

  --transition-duration: 300ms;
  --transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  --sm-screen: 576px;
  --md-screen: 768px;
  --lg-screen: 992px;
  --xl-screen: 1200px;
  --xxl-screen: 1400px;

  --gutter: 2.5rem;
  --container-width: 1440px;

  @media screen and (max-width: 768px) {
    --gutter: 1.25rem;
    --h2-font-size: clamp(2.625rem, 2.1467rem + 2.3913vw, 4rem);
  }
}
