@keyframes fadeIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.will-fade-in {
  opacity: 0;
}

.fade-in {
  animation: fadeIn 0.5s ease;
  animation-fill-mode: forwards;
}

@keyframes animate-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.will-animate-up {
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.5s ease;
  transition-delay: 0.25s;

  div,
  h1,
  h2,
  h3,
  p {
    will-change: transform, opacity;
  }
}

.animate-up {
  transform: translateY(0);
  opacity: 1;
}

.word-will-animate-up {
  .word {
    transform: translateY(50px);
    opacity: 0;
    transition: all 0.5s ease;
    transition-delay: 0.25s;
    display: block;
  }
}

.word-animate-up {
  .word {
    transform: translateY(0);
    opacity: 1;
  }

  @for $i from 1 to 14 {
    .word:nth-of-type(#{$i}) {
      transition-delay: $i * 0.1s;
    }
  }

  &.delay-1 {
    @for $i from 1 to 14 {
      .word:nth-of-type(#{$i}) {
        transition-delay: $i * 0.3s;
      }
    }
  }

  &.delay-2 {
    @for $i from 1 to 14 {
      .word:nth-of-type(#{$i}) {
        transition-delay: $i * 0.5s;
      }
    }
  }
}

.do-animate-up {
  animation: animate-up 0.5s ease forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  transform: translateX(100%);
  opacity: 0;
  animation: slide-in 0.5s ease forwards;
}

.delay-1 {
  animation-delay: 0.3s;
  transition-delay: 0.3s;
}

.delay-2 {
  animation-delay: 0.5s;
  transition-delay: 0.5s;
}

.delay-3 {
  animation-delay: 0.7s;
  transition-delay: 0.7s;
}

.delay-4 {
  animation-delay: 0.4s;
  transition-delay: 0.4s;
}

.delay-5 {
  animation-delay: 0.5s;
  transition-delay: 0.5s;
}

.delay-6 {
  animation-delay: 0.6s;
  transition-delay: 0.6s;
}

.delay-7 {
  animation-delay: 0.7s;
  transition-delay: 0.7s;
}

.delay-8 {
  animation-delay: 0.8s;
  transition-delay: 0.8s;
}

.delay-9 {
  animation-delay: 0.9s;
  transition-delay: 0.9s;
}

.delay-10 {
  animation-delay: 1s;
  transition-delay: 1s;
}

@keyframes glowAndFade {
  0% {
    filter: blur(0);
  }
  50% {
    filter: blur(20rem);
    transform: scale(10);
  }
  100% {
    opacity: 0;
  }
}

.payoff {
  animation: glowAndFade 10s ease forwards;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Pulse */
.pulse {
  animation: pulse 5s ease infinite;
}
