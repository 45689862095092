@use "sass:meta";
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css("fonts");
@include meta.load-css("design-tokens");
@include meta.load-css("typography");
@include meta.load-css("atoms");
@include meta.load-css("animation");

body {
  font-size: 16px;
}

a {
  color: var(--gold-color);
}

.retract {
  transform: translateY(-100%);
  will-change: transform;
}

.resource-grid {
  display: grid;
  grid-template-columns: 400px auto;
  gap: 3rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;

    .filters {
      position: fixed;
      top: 0;
      left: 0;
      width: 100dvw;
      height: 100dvh;
      transform: translateX(-100%);
      background-color: #fff;
      z-index: 100;
      transition: transform 0.3s ease;
      padding-top: 90px;
      padding-bottom: 2rem;
      overflow: auto;
      overscroll-behavior: contain;

      &.open {
        transform: translateX(0);
      }
    }
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  display: inline-block;
  height: 1.5rem;
  min-width: 1.5rem;
  outline: none;
  position: relative;
  vertical-align: middle;
  width: 1.5rem;
  cursor: pointer;
  accent-color: var(--gold-color);

  &:checked {
    background-color: var(--gold-color);
    border-color: var(--gold-color);
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  }
}

.main-nav .nav-links {
  button,
  a {
    position: relative;
  }
  button:after,
  a:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: -1;
  }
  button:hover:after,
  a:hover:after {
    opacity: 1;
  }
  button.active:after,
  a.active:after {
    opacity: 1;
  }
}

.nav {
  a {
    transition: color 0.3s ease;
    &:hover {
      color: var(--gold-color);
    }
  }

  svg {
    line {
      transition: color 0.3s ease;
      &:hover {
        stroke: var(--gold-color);
      }
    }
  }
}

.rich-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ul {
    list-style: disc;
    list-style-position: outside;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      margin-left: 1rem;
      list-style-position: outside;
      p {
        display: inline;
      }
    }
  }

  h2 {
    font-size: var(--h3-font-size);
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 2rem;
  }
}

.hidden-translate {
  transform: translateX(-50%) translateY(calc(-1 * 50% + 41px));
}

.ctas {
  a {
    transition: color 0.3s ease;
    &:hover {
      color: var(--gold-color);
    }
  }
}

table {
  th {
    background-color: var(--gold-color);
    text-align: left;
    font-weight: normal;
    &:first-child {
      padding-right: 1rem;
    }
    padding: 0.5rem;

    p {
      font-size: 1.25rem !important;

      @media screen and (max-width: 768px) {
        font-size: 1rem !important;
        line-height: 1.5 !important;
      }
    }
  }
  td {
    padding: 0.5rem;
    border: 1px solid #ccc;
    text-align: left;
    &:first-child {
      padding-right: 1rem;
    }

    p {
      font-size: 1rem !important;

      @media screen and (max-width: 768px) {
        font-size: 1rem !important;
        line-height: 1.5 !important;
      }
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  }
}

.territory-map {
  path {
    fill: #ededed;
    transition: fill 0.3s ease;
  }
}

.map-state {
  fill: var(--light-gray-color) !important;
}

.map-state:hover,
.map-state.selected {
  fill: var(--gold-color) !important;
}

.blur {
  backdrop-filter: blur(3px);
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.01);
  opacity: 0.5;
}

.flex-resource {
  flex: 0 1 calc(20% - 3rem);
  align-self: stretch;

  @media screen and (max-width: 1900px) {
    flex: 0 1 calc(33.333% - 2rem);
  }

  @media screen and (max-width: 1280px) {
    flex: 0 1 calc(50% - 1.5rem);
  }
}

.results {
  justify-content: flex-start;
  align-items: flex-start;
  height: max-content;
}

h1 sup,
h2 sup {
  font-size: 0.3em;
  line-height: 1;
  vertical-align: top;
  top: 0.5em;
}

.samples {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.swatch-title {
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  width: 160px;
  aspect-ratio: 1 / 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.elementor-widget-container,
.elementor-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.elementor,
.blog-post {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h1,
  h2,
  h3 {
    font-size: var(--h3-font-size);
    margin-bottom: 2rem;
    font-weight: 400;
  }

  ul {
    list-style: disc;
    list-style-position: outside;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      margin-left: 1rem;
      list-style-position: outside;
      p {
        display: inline;
      }
    }
  }
}
.elementor-column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.elementor-widget-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.nav,
.main-nav,
.footer {
  a {
    color: white;
  }
}

.results {
  a {
    color: #000 !important;
  }
}

.swatch-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 2rem;
}
